import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { HotToastService } from '@ngxpert/hot-toast';
import { ApiService } from 'src/libs/api/api.service';

@Component({
  selector: 'fini-server-invite-link',
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './server-invite-link.component.html',
  styleUrl: './server-invite-link.component.scss',
})
export class ServerInviteLinkComponent implements OnInit {
  loading = signal(true);

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private toast: HotToastService,
  ) {}

  ngOnInit() {
    const token = this.route.snapshot.paramMap.get('token');

    console.log(token);
    if (!token) {
      this.toast.error('Invalid invite link');
      this.loading.set(false);
      return;
    }

    this.joinServer(token);
  }

  private joinServer(token: string) {
    this.api.post(`/server/invite/use`, { token }).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.toast.success('Successfully joined server');
          // Using window.location.href to force server list reload
          window.location.href = `/dashboard`;
        } else {
          if (response.code === 440) {
            this.unauthorised_redirect();
          } else {
            this.toast.error(response.message || 'Failed to join server');
            setTimeout(() => {
              window.location.href = '/dashboard';
            }, 2500);
          }
        }
      },
      error: (err) => {
        if (err.code === 440) {
          this.unauthorised_redirect();
        } else {
          this.toast.error(
            err.error?.message || 'An error occurred while joining the server',
          );
          setTimeout(() => {
            window.location.href = '/dashboard';
          }, 2500);
        }
      },
      complete: () => {
        this.loading.set(false);
      },
    });
  }

  private unauthorised_redirect() {
    const currentUrl = window.location.href;
    window.location.href = `/login?redirect=${encodeURIComponent(currentUrl)}`;
  }
}
