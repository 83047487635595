<div class="flex items-center justify-center min-h-screen">
  <div class="text-center">
    <div role="status" *ngIf="loading()">
      <mat-progress-spinner
        mode="indeterminate"
        class="mx-auto my-8"
        [diameter]="64"
      ></mat-progress-spinner>
      <p class="mt-4 text-gray-600">Joining server...</p>
    </div>
    <div *ngIf="!loading()">
      <h1 class="text-2xl font-bold">You're being redirected...</h1>
    </div>
  </div>
</div>
